import { throttle } from 'throttle-debounce';
import './assets/style/normalize.css';
import './style.less';
import { isIE } from './utils';

process.env.NODE_ENV === 'development' && require('./index.html');

if (document.body.clientWidth < 1000) {
    window.location.href = 'https://www.mingtukeji.com/h5';
}
window.onresize = () => {
    if (document.body.clientWidth <= 1000) {
        window.location.href = 'https://www.mingtukeji.com/h5';
    }

};

const pages = [{
    name: 'top',
    height: 712
}, {
    name: 'introduction',
    height: 568
}, {
    name: 'advantage'
}, {
    name: 'service'
}, {
    name: 'product'
}, {
    name: 'client'
}, {
    name: 'case'
}, {
    name: 'aftersale'
}, {
    name: 'connect'
}];

// 滚动和初始化高亮滚动菜单
const loadScroll = () => {
    // 滚动根节点
    const root = document.querySelector('#root') as HTMLDivElement;
    // 窗口高度
    const clientHeight = document.body.clientHeight;
    // 判断是否有锚点，有锚点直接跳转
    if (window.location.hash.length > 0) {
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
    }
    const scrollItems = document.querySelectorAll<HTMLDivElement>('.scroll-item');
    const menuItems = document.querySelectorAll<HTMLLinkElement>('.menu-fixed-item');
    setTimeout(() => {
        for (let index = 0; index < scrollItems.length; index++) {
            const item = scrollItems[index];
            if ((root.scrollTop >= item.offsetTop - 200) && (root.scrollTop < item.offsetTop + clientHeight - 200)) {
                document.querySelector('.menu-fixed-item--this')?.classList.remove('menu-fixed-item--this');
                menuItems[index + 1].classList.add('menu-fixed-item--this');
                break;
            }
        }
    }, 1000 * 1);

    // 点击按钮更新悬浮目录高亮
    for (let index = 0; index < menuItems.length; index++) {
        const item = menuItems[index];
        item.onclick = () => {
            document.querySelector('.menu-fixed-item--this')?.classList.remove('menu-fixed-item--this');
            item.classList.add('menu-fixed-item--this');
        };
    }

    // 顶部按钮点击跳转
    const topMenuItems = document.querySelectorAll<HTMLLinkElement>('.top-menu a');
    for (let index = 0; index < topMenuItems.length; index++) {
        const item = topMenuItems[index];
        item.onclick = () => {
            setTimeout(() => {
                for (let j = 0; j < scrollItems.length; j++) {
                    const item = scrollItems[j];
                    if ((root.scrollTop >= item.offsetTop - 200) && (root.scrollTop < item.offsetTop + clientHeight - 200)) {
                        document.querySelector('.menu-fixed-item--this')?.classList.remove('menu-fixed-item--this');
                        menuItems[j + 1].classList.add('menu-fixed-item--this');
                    }
                }
            }, 1000 * 1);
        };
    }

    const onwheel = throttle(500, true, (e: WheelEvent) => {
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            const element = document.querySelector(`#${page.name}`) as HTMLDivElement;

            if ((root.scrollTop >= element.offsetTop - 200) && (root.scrollTop < element.offsetTop + (page.height || clientHeight) - 200)) {
                document.querySelector('.menu-fixed-item--this')?.classList.remove('menu-fixed-item--this');
                // menuItems[index + 1].classList.add('menu-fixed-item--this');
                if (e.deltaY > 0) {
                    if (window.location.hash === '#connect') {
                        window.location.hash = 'footer';
                    } else {
                        pages[i + 1] && (window.location.hash = pages[i + 1].name);
                        menuItems[i]?.classList.add('menu-fixed-item--this');
                    }
                } else {
                    if (window.location.hash === '#footer') {
                        window.location.hash = 'connect';
                        menuItems[i - 1]?.classList.add('menu-fixed-item--this');
                    } else {
                        pages[i - 1] && (window.location.hash = pages[i - 1].name);
                        menuItems[i - 2]?.classList.add('menu-fixed-item--this');
                    }
                }
            }
        }

    });
    if (!isIE()) {
        root.onwheel = (e) => {
            onwheel(e);
            return false;
        };
    } else {
        root.style.overflowY = 'auto';
    }

};
loadScroll();

// 客户案例选择PC和移动端
const loadCaseEvent = () => {
    const tabs = document.querySelectorAll('.case .tabs .tabs-item');
    for (let index = 0; index < tabs.length; index++) {
        const tab = tabs[index];
        tab.addEventListener('click', () => {
            if (!tab.classList.contains('tabs-item--this')) {
                document.querySelector('.tabs-item--this')?.classList.remove('tabs-item--this');
                tab.classList.add('tabs-item--this');
            }

            const pc = document.querySelector('.content-pc') as HTMLDivElement;
            if (tab.classList.contains('tabs-pc')) {
                pc.classList.remove('content-item--this');
            } else {
                pc.classList.add('content-item--this');
            }
        });
    }
};
loadCaseEvent();